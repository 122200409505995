import React from 'react';

import '../css/home.css';
import Contacto from './Contacto';
import Nosotros from './Nosotros';
import Servicio from './Servicio';

const Inicio = () => {
  return (
    <div className="op-body">
      <div id="home" className="op-panel start">
        <div className="info">
          <h2>
            <span>Empresa</span>
          </h2>
          <h2>
            <span>Control</span>
            <div>
              <span>de</span>
              <span>plagas</span>
            </div>
          </h2>
          <p>
            Expertos con mas de 7 años experiencia, en control de plagas,
            autorizados por el MINSA RESOL. N° 068-2019
          </p>
          <label>COTIZA HOY</label>
          <a
            href="https://wa.me/51947040968?text=Hola%20necesito%20informacion"
            target="_blank"
            rel="noopener noreferrer"
            className="whatsapp"
          />
        </div>
        <div className="image" />
        <div className="contact">
          <label>Solicita tu inspecci&oacute;n gratis para tu empresa</label>
          <span className="icon-list" />
        </div>
      </div>
      <Nosotros />
      <Servicio />
      <Contacto />
    </div>
  );
};

export default Inicio;
