import React from 'react';
import { Link, NavLink } from 'react-router-dom';

import '../css/header.css';

const Header = () => {
  const handleHeader = (e) => {
    let element = e.target;
    element
      .closest('nav.op-header')
      .querySelector("input[type='checkbox'").checked = false;
  };

  return (
    <>
      <nav className="op-header">
        <div className="op-contacts">
          <span>Cel: 947 040 968</span>
          <span className="email-icon">informes@emsag-peru.com</span>
        </div>
        <input type="checkbox" id="checkMenu"></input>
        <label htmlFor="checkMenu" className="checkButton">
          <span></span>
          <span></span>
          <span></span>
        </label>
        <a href="#" className="op-logo"></a>
        <ul>
          <li>
            <a
              href="#home"
              //activeClassName='active'
              onClick={(e) => handleHeader(e)}
            >
              Inicio
            </a>
          </li>
          <li>
            <a
              href="#about"
              //activeClassName='active'
              onClick={(e) => handleHeader(e)}
            >
              Nosotros
            </a>
          </li>
          <li>
            <a
              href="#service"
              //activeClassName='active'
              onClick={(e) => handleHeader(e)}
            >
              Servicios
            </a>
          </li>
          <li>
            <a
              href="#contact"
              //activeClassName='active'
              onClick={(e) => handleHeader(e)}
            >
              Cont&aacute;ctenos
            </a>
          </li>
        </ul>
        <div className="op-redes">
          <a
            href="https://wa.me/51947040968?text=Hola%20necesito%20informacion"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fa fa-whatsapp" aria-hidden="true"></i>
          </a>
          <a
            href="https://web.facebook.com/EMSAG.PERU/?_rdc=1&_rdr"
            target="_blank"
            rel="noopener noreferrer"
            className="facebook"
          >
            <div></div>
          </a>
        </div>
      </nav>
    </>
  );
};

export default Header;
